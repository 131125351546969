import styled, { css } from 'styled-components';
import { media, glsp, themeVal } from '@devseed-ui/theme-provider';

const renderUniGrid = ({ grid }) => {
  const grids = Object.keys(grid).map((brk) => {
    const [start, end] = grid[brk];
    const mediaQuery = media[brk];

    const cols = end - (start - 1);
    const contentColumns = Array(cols)
      .fill(0)
      .map(
        (_, i) => css`
        [col-${i + start}] 1fr
      `
      );

    return css`
      ${mediaQuery`
        grid-column: ${start} / span ${cols};
        grid-template-columns: ${contentColumns};
      `}
    `;
  });

  return grids;
};

const UniversalGrid = styled.div`
  display: grid;
  ${media.smallUp`
    grid-column-gap: ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-column-gap: ${glsp(themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-column-gap: ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-column-gap: ${glsp(themeVal('layout.gap.xlarge'))};
  `}

  ${renderUniGrid}
`;

export default UniversalGrid;
