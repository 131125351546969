import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { media, glsp, themeVal } from '@devseed-ui/theme-provider';

import Constrainer from '../../styles/constrainer';
import UniversalGrid from '../../styles/universal-grid';
import UniversalGridder from '../../styles/universal-gridder';

import SandboxLayout from './_layout';

const renderOffsetAreas = ({ offset }) => {
  switch (offset) {
    case 'indented':
      return css`
        ${Caixa} {
          grid-column: span 4;
          grid-template-areas:
            'blue blue blue blue'
            'red red red red';

          ${media.mediumUp`
            grid-column: span 8;
            grid-template-areas:
              'blue blue blue blue red red red red';
          `}

          ${media.largeUp`
            grid-column: 2 / span 11;
            grid-template-areas:
              'blue blue blue blue blue blue red red red red red';
          `}
        }
      `;
    case 'none':
      return css`
        ${Caixa} {
          grid-column: span 4;
          grid-template-areas:
            'blue blue blue blue'
            'red red red red';

          ${media.mediumUp`
            grid-column: span 8;
            grid-template-areas:
              'blue blue blue blue red red red red';
          `}

          ${media.largeUp`
            grid-column: span 12;
            grid-template-areas:
              'blue blue blue blue blue blue red red red red red red';
          `}
        }
      `;
  }
};

const Pai = styled(Constrainer)`
  display: grid;
  grid-gap: 0 ${glsp(themeVal('layout.gap.xsmall'))};
  grid-template-columns: repeat(4, 1fr);

  ${media.smallUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.medium'))};
    grid-template-columns: repeat(8, 1fr);
  `}

  ${media.largeUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.large'))};
    grid-template-columns: repeat(12, 1fr);
  `}

  ${media.xlargeUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.xlarge'))};
  `}

  ${renderOffsetAreas}
`;

const Caixa = styled.div`
  display: grid;
  height: 10rem;

  ${media.smallUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.small'))};
  `}

  ${media.mediumUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.medium'))};
  `}

  ${media.largeUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.large'))};
  `}

  ${media.xlargeUp`
    grid-gap: 0 ${glsp(themeVal('layout.gap.xlarge'))};
  `}

  .blue {
    grid-area: blue;
    background-color: blue;
  }

  .red {
    grid-area: red;
    background-color: red;
  }
`;

const NovaCaixa = styled(UniversalGrid)`
  .blue,
  .red {
    grid-column: col-1 / span 4;
  }

  ${media.mediumUp`
      .blue {
        grid-column: col-1 / span 4;
      }
      .red {
        grid-column: col-5 / span 4;
      }
    `}

  ${media.largeUp`
      .blue {
        grid-column: col-2 / span 5;
      }
      .red {
        grid-column: col-7 / span 6;
      }
    `}
`;

// How to add a new sandbox page:
// Follow the instructions on ./_layout.js

const SandboxMedia = ({ location }) => {
  return (
    <SandboxLayout location={location} title='Sandbox'>
      <UniversalGridder>
        <UniversalGridder
          style={{ backgroundColor: 'blue' }}
          grid={{
            smallUp: ['full-start', 'full-end'],
            mediumUp: ['content-3', 'content-4'],
            largeUp: ['content-3', 'content-5']
          }}
        >
          BLUE
        </UniversalGridder>
        <UniversalGridder
          style={{ backgroundColor: 'red' }}
          grid={{
            smallUp: ['full-start', 'full-end'],
            mediumUp: ['full-start', 'full-end'],
            largeUp: ['content-6', 'full-end']
          }}
        >
          RED
        </UniversalGridder>
      </UniversalGridder>

      <Constrainer>
        <UniversalGrid
          grid={{
            smallUp: [1, 4],
            mediumUp: [1, 8],
            largeUp: [1, 12]
          }}
        >
          <NovaCaixa
            grid={{
              smallUp: [1, 4],
              mediumUp: [1, 8],
              largeUp: [2, 12]
            }}
          >
            <div className='blue'>Hello</div>
            <div className='red'>Hello</div>
          </NovaCaixa>
        </UniversalGrid>
      </Constrainer>

      <Pai offset='indented'>
        <Caixa>
          <div className='blue'>Hello</div>
          <div className='red'>Hello</div>
        </Caixa>
      </Pai>

      <Pai offset='none'>
        <Caixa>
          <div className='blue'>Hello</div>
          <div className='red'>Hello</div>
        </Caixa>
      </Pai>
    </SandboxLayout>
  );
};

export default SandboxMedia;

SandboxMedia.propTypes = {
  location: PropTypes.object
};
